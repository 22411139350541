<template>
 <div >
    <component_svg>   
    </component_svg>

</div>
</template>

<script>

export default {
    components: {
    component_svg: () => import("../components/svg_well.vue"), 
  },
    data() {
        return {
  
        };
    },
    mounted() {
       
        //   this.skey++;
    },
    methods: {
       

    },

};
</script>
 